// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
$primary-color: #e44140;

body {
  overflow-x: hidden;
  font-family: "Roboto";

}

body > .container-fluid:first-of-type, body > .container:first-of-type {
  padding-top: 60px;
}

//.panel-body {
//  font-family: "Roboto Slab";
//}
.semaphore-logo {
  height: 50px;
}

.text-muted {
  color: #666;
}

.text-primary {
  color: #f9be16;
}

p {
  font-size: 14px;
  line-height: 1.75;
}

p.large {
  font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
  outline: 0;
}

a {
  color: #000000;
}

a:hover,
a:focus,
a:active,
a.active {
  color: #fec503;
}

a.see-more-messages {
  font-size: 13px;
  right: 20px;
  position: absolute;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.5pt;
}

.img-centered {
  margin: 0 auto;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-darkest-gray {
  background-color: #222;
}

.btn {
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.5pt;
}

.btn-primary {
  border: none;
  background-color: #e64a40;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #edc025;
  background-color: #e64a40;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  border-color: #f9be16;
  background-color: #a7535e;
}

.btn-primary .badge {
  color: #f9be16;
  background-color: #fff;
}

.btn-xl {
  padding: 20px 40px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: #e64a40
}

.fa-inverse {
  color: #e64a40 !important;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  //border-color: #f6bf01;
  //color: #fff;
  //background-color: #fec503;
  //background-color: #a5313c;
  color: #f9be16;
  background-color: #d53039;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
  background-color: #d53039;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  border-color: #f9be16;
  background-color: #f9be16;
}

.btn-xl .badge {
  color: #f9be16;
  background-color: #fff;
}

.navbar-default {
  border-color: transparent;
  background-color: #222;
}

.navbar {
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.5pt;
}

label {
  font-weight: normal;
  font-style: normal;
  letter-spacing: .5pt;

}
//.alert-warning {
//  color: #000000 !important;
//}
//
//.alert-warning a {
//  color: #fec503 !important;
//}

// .nav li a {
//   font-family: "Montserrat" !important;
// }
// .nav li a.active,
// .nav li a:hover {
//   background-color: transparent !important;
//   border-bottom: #fec503 solid 3px !important;
//   padding-bottom: 5px !important;
// }
// .navbar-default .navbar-brand:hover,
// .navbar-default .navbar-brand:focus,
// .navbar-default .navbar-brand:active,
// .navbar-default .navbar-brand.active {
//   color: #fec503;
// }
// .navbar-fixed-top {
//   padding-top: 10px;
// }
// .navbar-fixed-top .container {
//   width: 95% !important;
// }
// .navbar-default .navbar-collapse {
//   border-color: rgba(255, 255, 255, .02);
// }
// .navbar-toggle {
//   font-size: 25px;
//   margin: 0px;
// }

// .navbar-default .navbar-toggle {
//   border-color: #f9be16;
//   background-color: #f9be16;
// }

// .navbar-default .navbar-toggle .icon-bar {
//   background-color: #fff;
// }

// .navbar-default .navbar-toggle:hover,
// .navbar-default .navbar-toggle:focus {
//   background-color: #f9be16;
// }

// .navbar-default .nav li a {
//   text-transform: uppercase;
//   font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-weight: 400;
//   letter-spacing: 1px;
//   color: #fff;
// }

// .navbar-default .nav li a:hover,
// .navbar-default .nav li a:focus {
//   outline: 0;
//   color: #f9be16;
// }

// .navbar-default .navbar-nav > .active > a {
//   border-radius: 0;
//   color: #fff;
//   background-color: #f9be16;
// }

// .navbar-default .navbar-nav > .active > a:hover,
// .navbar-default .navbar-nav > .active > a:focus {
//   color: #fff;
//   background-color: #fec503;
// }

@media(min-width: 768px) {
  .navbar-default {
    padding: 25px 0;
    border: 0;
    background-color: transparent;
    // -webkit-transition: padding .15s;
    // -moz-transition: padding .15s;
    // transition: padding .15s;
  }

  .navbar-default .navbar-brand {
    font-size: 2em;
    // -webkit-transition: all .15s;
    // -moz-transition: all .15s;
    // transition: all .15s;
  }

  .navbar-default .navbar-nav > .active > a {
    border-radius: 3px;
  }

  .navbar-default.navbar-shrink {
    padding: 10px 0;
    background-color: #222;
  }

  .navbar-default.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}

header {
  text-align: center;
  color: #fff;
  background-color: black;
  height: 100%;
}

header .intro-text {
  padding-top: 100px;
  padding-bottom: 100px;
}

header .intro-text .intro-lead-in {
  margin-bottom: 25px;
  font-family: Montserrat, "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: italic;
  line-height: 22px;
}

header .intro-text .intro-heading {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
}

@media(min-width: 768px) {
  header {
    vertical-align: middle;
  }
  header .intro-text {
    padding-top: 135px;
    padding-bottom: 200px;
  }

  header .intro-text .intro-lead-in {
    margin-bottom: 25px;
    font-family: Montserrat, "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-style: italic;
    line-height: 40px;
  }

  header .intro-text .intro-heading {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 75px;
    font-weight: 700;
    line-height: 75px;
  }
}

section {
  padding: 50px 0;
}

section h2.section-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 40px;
}

section h3.section-subheading {
  margin-bottom: 75px;
  text-transform: none;
  font-family: Montserrat, "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@media(min-width: 768px) {
  section {
    padding: 100px 0;
  }
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

.doc-heading {
  color: #e64a40;
  margin: 15px 0;
  text-transform: uppercase;
}

footer {
  padding: 25px 0;
  text-align: center;
}

footer span.copyright {
  text-transform: uppercase;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 40px;
}

footer ul.quicklinks {
  margin-bottom: 0;
  text-transform: uppercase;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 40px;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  outline: 0;
  color: #fff;
  background-color: #222;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #f9be16;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: 0;
}

::-moz-selection {
  text-shadow: none;
  background: #f9be16;
}

::selection {
  text-shadow: none;
  background: #f9be16;
}

img::selection {
  background: 0 0;
}

img::-moz-selection {
  background: 0 0;
}

body {
  webkit-tap-highlight-color: #f9be16;
}

.pricing-panel {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 30px;
}

.footer-image {
  padding: 25px 0;
}

.footer-links {
  background-color: black;
}

.fa-sign-out {
  padding-top: 3px;
}

ul#errors {
  list-style-type: none;
}

canvas#usage {
  width: 100%;
  hieght: auto;
}

#curl-code {
  font-family: monospace;
  word-wrap: break-word;
}

#splashImage {
  display: inline;
}

#userContainer, #senderNameContainer {
  display: flex;
}

#addUser, #addSenderName {
  padding: 9px;
}

#userTable .action, #senderTable .action {
  text-align: center;
}

.tab-content .well {
  font-family: monospace;
  word-wrap: break-word;
}
#submitSender {
  margin-top: 10px;
}

.btn-transparent {
  background-color: transparent !important;
  border: #F5DD98 solid 2px;
  border-radius: 50px;
  color: #F5B216 !important;
  padding: 12px 40px !important;
}

.alert-warning a#id {
  background-color: transparent !important;
  border: #F5DD98 solid 2px;
  border-radius: 50px;
  //color: #F5B216 !important;
  letter-spacing: 1.5pt;
  margin: 0 2%;
  padding: 5px 25px !important;
  position: absolute;
  top: 10px;
}

.start .container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.start div.details-container {
  color: #A3A4A3;
  min-height: 360px;
}
.start .start-icon {
  height: 130px;
}
.gettingStarted {
  background-color: rgba(170,0,0,0.85);
}
.gettingStarted .container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.gettingStarted .sub-title {
  color: #FFFFFF;
  font-size: 16px;
}
.gettingStarted h2 {
  color: #ffffff;
}
.gettingStarted .steps {
  height: 400px;
  padding: 5px 20px;
}
.gettingStarted .steps .card {
  background-color: #fff;
  border-radius:20px;
  height: 100%;
  margin: 5px 20px;
}
.gettingStarted .steps .card .steps-img {
  padding-top: 35px;
}
.gettingStarted .steps .card .details-container {
  position: relative;
}
.gettingStarted .steps .card .numbers {
  left:20px;
  position: absolute;
  top:40px;
}
.gettingStarted .steps .card .details-container .text-left {
  padding: 10px;
}
.gettingStarted .steps .card .details-container .details {
  padding: 10px 15px 0 55px;
}
.gettingStarted .steps .card .details-container .details .title {
  font-family: 'Montserrat';
  font-size: 20px;
}
.gettingStarted .steps .card .details-container .details .desc {
  color: #838383;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.gettingStarted .btn-transparent {
  width: 90%;
}
.account-details {
  background-color: #636363;
  height: 60px;
}
.account-details .credits-container {
  //border-right: #E5E5E5 solid 2px;
  height: 45px;
  margin-top: 10px;
}
.account h5 {
  color: #f9be16;
  font-size: 12px;
  text-transform: lowercase;
}
.account-details .credits-container #credits {
  border: #fff solid 2px;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  left: 15px;
  padding: 4px 15px;
  position: absolute;
  top: 20px;
  text-align: center;
  width:85%;
}
.account-details .credits-container #credits .btn.btn-primary {
  border-radius: 0px 5px 5px 0px;
  background-color: #fff;
  color: #524A4A;
  height: 27px;
  left: 100%;
  padding: 5px 10px;
  position: absolute;
  top: -2px;
}
.account-details #api-key {
  border: #fff solid 2px;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  font-weight: normal;
  left: 15px;
  font-size: 14px;
  padding: 4px 15px;
  position: absolute;
  top: 10px;
  text-align: center;
  width:83%;
}

.account-details #api-key .btn.btn-primary {
  border-radius: 0px 5px 5px 0px;
  background-color: #fff;
  color: #524A4A;
  height: 27px;
  left: 100%;
  padding: 5px 10px;
  position: absolute;
  top: -2px;
}
input#search {
  background-image: url('/images/layouts/default/search.png');
  background-color: #F5F5F5;
  background-position: 7px 7px;
  background-repeat: no-repeat;
  border-radius: 15px;
  color: #7C7C7C;
  padding-left: 30px;
}
tbody {
  font-size: 14px !important;
}
.table-links {
  color: #f9be16 !important;
}

.message_types {
  width: auto;
  margin:-4px 5px
}
.account-details .credits-container .title {
  margin: 15px;
  letter-spacing: 0;
}

ul.accounts-tabs li {
  width: 33.33%;
  text-align: center;
  background-color: #636363;
}
ul.accounts-tabs li a {
  color: #fff;
}

.nav-tabs.accounts-tabs>li.active>a, .nav-tabs.accounts-tabs>li.active>a:focus {
  border: none;
  -webkit-box-shadow: inset 0 -2px 0 #fec503;
  box-shadow: inset 0 -2px 0 #fec503;
  color: #fec503;
}

.btn.web-tool {
  padding: 0px 5px;
  margin-left: 10px;
}

.account-details .api-container .api-content {
  display: inline-flex;
  margin-top: -10px;
}

/****** MEDIA STYLES *********/

@media (max-width: 768px) {
  .logo-container {
    padding: 17px 0 0 0;
  }
  .message_types {
    margin:-4px 5px !important;
  }
}

@media (max-width: 992px) and (min-width: 768px)  {
  // .navbar-nav>li>a {
  //   font-size: 12px;
  //   padding: 20px 10px;
  //   margin: -15px 0px;
  // }
  // .semaphore-logo {
  //   height: 40px;
  // }
}

@media (max-width: 992px) {
  .start div.details-container{
    min-height: auto !important;
    padding: 20px 0;
  }
  .alert-warning a#id {
    display: inline-flex;
    position: inherit !important;
  }
}

@media (max-width: 740px) {
  .content.messages_list {
    display: none;
  }
}

@media screen and (max-width: 663px) {
  .account-details {
    height: auto;
  }
  .account-details .credits-container {
    width: 100%;
    height: auto;
  }
  .account-details .credits-container #credits {
    top: 0px;
    left: 30%;
    width: auto;
  }
  .account-details .api-container {
    width: 100%;
  }
  .account-details .api-container #api-key {
    top: 0;
    left: 20%;
    font-size: 12px;
    width: 65%;
  }
  .account-details .api-container #api-key i.fa-key {
    display: none;
  }
  .account-details .credits-container .title {
    margin: 15px 0 5px 0;
  }
  .account-details .api-container .api-content {
    margin-top: 0;
  }
  .account-details #api-key .btn.btn-primary {
    height: 25px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 993px) {
  .gettingStarted .steps .card .details-container .details .title {
    font-size: 14px;
  }
  .content.start h3 {
    font-size: 24px;
  }
  .content.start h6 {
    font-size: 14px;
  }
  .content.start p.details {
    font-size: 12px;
  }
}

.error-tooltip + .tooltip > .tooltip-inner { background-color: #f00; }
.error-tooltip + .tooltip > .tooltip-arrow { border-bottom-color:#f00; }

.error-text {
  color: #f00;
}



/* ------- V2 ------- */
body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  // background-color: #FFF;
  color: #1e1e1e;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  outline: none !important;
}
button {
  outline: none !important;
}
input {
  outline: none !important;
}
select {
  outline: none !important;
}
textarea {
  outline: none !important;
}

.grayBackground {
  background-color: #fafbfc;
}

.max-container {
  max-width: 1000px;
  margin: auto;

  &.padding-top {
    padding-top: 20px;
  }

  @media screen and (max-width: 1000px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.normal-page-container-padding, #mainCol {
  padding-top: 35px;
  padding-bottom: 65px;

  @media screen and (max-width: 480px) {
    padding-bottom: 25px;
    padding-top: 15px;
  }
}

.align-right {
  text-align: right;
}

.table-responsive {
  margin: 0;
  border: none;
}

.table-no-margin {
  margin: 0;
}

.semaphore-primary-button {
  border: none;
  background-color: $primary-color;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;

  &.text-initial {
    text-transform: initial;
  }

  &:hover {
    background-color: #c13736;
    color: #FFF;
    text-decoration: none;
  }

  &:active {
    background-color: #a02e2d;
  }
}

.semaphore-yellow-button {
  border: none;
  background-color: #f9be19;
  // color: #1e1e1e;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;

  &.text-initial {
    text-transform: initial;
  }

  &:hover {
    background-color: #c79919;
    text-decoration: none;
  }
}

#semaphoreNavbar {
  min-height: 65px;
  max-height: 65px;
  box-shadow: none;
  background-image: none;
  background-color: #FFF;
  padding: 0 55px;
  border: none;
  box-shadow: 0px 3px 10px #e6e7e8;
  margin-bottom: 0;
  letter-spacing: initial;
  width: 100%;
  z-index: 15 !important;
  top: 0;
  border-radius: 0;
  position: fixed;

  @media screen and (max-width: 1110px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media screen and (max-width: 480px) {
    min-height: 50px;
    max-height: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.affix {
    box-shadow: 0px 3px 10px #e6e7e8;
  }
  
  &.affix-no-shadow {
    box-shadow: none;
  }

  .navbar-header {
    float: left;
    margin: 0;

    .navbar-brand {
      height: 65px;
      padding: 15px 0;
      padding-left: 0;
      padding-right: 50px;
      margin: 0;

      &.mobile {
        display: none;
      }

      @media screen and (max-width: 1000px) {
        padding-right: 15px;
      }

      @media screen and (max-width: 630px) {
        display: none;
        
        &.mobile {
          display: block;
        }
      }

      @media screen and (max-width: 480px) {
        padding-right: 0;
        height: 50px;
        padding: 8px 0;
      }

      img {
        height: 100%;
      }
    }
  }
  
  .mobile-dropdown-container {
    overflow: auto;
    position: fixed;
    right: -100%;
    width: 100%;
    max-width: 220px;
    background: #FFF;
    box-shadow: 0px 2px 5px #e6e7e8;
    border-bottom-left-radius: 2px;
    -webkit-transition: right 0.2s ease;
       -moz-transition: right 0.2s ease;
        -ms-transition: right 0.2s ease;
         -o-transition: right 0.2s ease;
            transition: right 0.2s ease;

    &.show {
      @media screen and (max-width: 860px) {
        right: -5px;
      }
    }

    .mobile-dropdown-menu {
      box-shadow: -5px 3px 10px #e6e7e8 inset;
      list-style-type: none;
      margin: 0;
      padding: 5px 0;
      padding-top: 5px;

      li {
        font-size: 11px;
        color: #5b6670;
        background: none;
        padding: 0;
        text-transform: initial;
        letter-spacing: initial;
        font-weight: normal;

        &.divider {
          background-color: #e9eaeb;
          margin-bottom: 5px;
          margin-top: 5px;
        }

        &.header-title {
          padding: 7px 15px;
          font-size: 8px;
          color: #7c868e;
          text-transform: uppercase;
        }

        a {
          background: none;
          padding: 0;
          border: none;
          color: #5b6670;
          text-transform: initial;
          letter-spacing: initial;
          font-weight: normal;

          .item-container {
            background: none;
            padding: 7px 15px;
            position: relative;


            &.gold-item {
              color: #e59c24;
            }

            .count {
              position: absolute;
              background: #3d97b5;
              padding: 4px 7px;
              height: 18px;
              line-height: 10px;
              border-radius: 8px;
              top: 0;
              right: 12px;
              bottom: 0;
              margin: auto;
              font-size: 11px;
              color: #FFF;
            }
          }

          &:hover {
            color: #1a1a1a;

            .item-container {
              background: #f6f8fa;
            }
          }
        }

        &.accounts-item {
          a {

            &:hover {
              .item-table {
                background: #f6f8fa;
              }
            }

            .item-table {
              display: table;
              vertical-align: middle;
              padding: 5px 15px;
              width: 100%;

              .profile-image {
                display: table-cell;
                vertical-align: middle;
                width: 26px;

                .image-container {
                  height: 26px;
                  width: 26px;
                  border-radius: 26px;
                  background-size: cover;
                  background-position: center;
                }
              }

              .profile-detail {
                display: table-cell;
                vertical-align: middle;
                padding-left: 8px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 140px;
              }

              .caret-container {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                width: 50px;

                i {
                  font-size: 9px;
                  color: #c1c6c9;
                }
              }
            }
          }
        }

        &.icon-right {
          &:hover {
            .item-table {
              background: #f6f8fa;
            }
          }

          .item-table {
            display: table;
            vertical-align: middle;
            padding: 7px 15px;

            .text {
              display: table-cell;
              vertical-align: middle;
              width: 100%;
            }

            .caret-container {
              display: table-cell;

              i {
                font-size: 12px;
                color: #c1c6c9;
              }
            }
          }
        }
      }
    }
  }


  .mobile-nav-list {
    overflow: auto;
    position: fixed;
    right: -100%;
    width: 100%;
    max-width: 300px;
    background: #FFF;
    box-shadow: 0px 2px 5px #e6e7e8;
    border-bottom-left-radius: 2px;
    -webkit-transition: right .2s ease;
       -moz-transition: right .2s ease;
        -ms-transition: right .2s ease;
         -o-transition: right .2s ease;
            transition: right .2s ease;

    &.show {
      @media screen and (max-width: 860px) {
        right: -5px;
      }
    }

    .mobile-nav-bar {
      box-shadow: -5px 3px 10px #e6e7e8 inset;
      list-style-type: none;
      margin: 0;
      padding: 25px;
      padding-top: 5px;
      padding-bottom: 10px;

      li {
        height: 38px;
        overflow: hidden;

        a {
          .nav-button {
            padding: 8px 5px;
            text-transform: uppercase;
            color: #5b6670;
            font-size: 12px;
            font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: normal;
            -webkit-transition: padding .2s ease;
               -moz-transition: padding .2s ease;
                -ms-transition: padding .2s ease;
                 -o-transition: padding .2s ease;
                    transition: padding .2s ease;
          }
          

          &:hover .nav-button {
            background: none;
            box-shadow: none;
            border-bottom: 3px solid $primary-color;
            padding-bottom: 5px;
            color: $primary-color;
          }
        }
      }
    }

    .mobile-navbar-login-buttons {
      border-top: 1px solid #e6e7e8;
      padding: 25px;
      padding-top: 10px;
      list-style-type: none; 
      margin-bottom: 0;

      li {
        padding: 8px 0;

        a {
          padding: 6px 20px;
          border: 1px solid $primary-color;
          background-color: $primary-color;
          color: #FFF;
          border-radius: 2px;
          display: block;
          width: 115px;
          margin: 0;
          text-align: center;

          &:hover {
            background-color: #c13736;
            border: 1px solid #c13736;
          }

          &.transparent-button {
            color: $primary-color;
            background-color: #FFF;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px transparent;
            overflow: hidden;
            -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
            -webkit-transition-property: color, background-color;
            transition-property: color, background-color;


            &:hover {
              color: #FFF;
              background-color: $primary-color;
              border: 1px solid $primary-color;
             }
          }
        }

        &:first-child {
          padding-right: 13px;
        }
      }
    }
  }

  .nav-list-container {

    .navbar-nav {
      float: left;

      &.page-navs {
        @media screen and (max-width: 860px) {
          display: none;
        }
      }

      &.nav-min-height {
        min-height: 65px;
        max-height: 65px;
        overflow: hidden;
      }

      .navbar-toggle {
        display: none;
        margin: 0;
        margin-left: 5px;
        margin-right: -10px;
        
        &:hover {
          .icon-bar {
            background: #1e1e1e;
          }
        }

        @media screen and (max-width: 860px) {
          display: block;
        }
      }

      .hide-on-mobile {
        @media screen and (max-width: 860px) {
          display: none;
        }
      }

      li {
        float: left;

        a {
          padding-top: 22px;
          padding-bottom: 20px;
          text-transform: uppercase;
          color: #5b6670;
          font-size: 12px;
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: normal;
          -webkit-transition: padding .2s ease;
             -moz-transition: padding .2s ease;
              -ms-transition: padding .2s ease;
               -o-transition: padding .2s ease;
                  transition: padding .2s ease;

          &:hover {
            background: none;
            box-shadow: none;
            border-bottom: 3px solid $primary-color;
            padding-bottom: 17px;
            color: $primary-color;
          }
        }

        &.active {
          a {
            background: none;
            box-shadow: none;
            border-bottom: 3px solid $primary-color;
            padding-bottom: 17px;
            color: $primary-color;
            border-radius: 0;

            &:hover {
              background: none;
            }
          }
        }
      }
      
      &.navbar-right {
        float: right;

        &.navbar-login-buttons {
          margin: 0;

          li {
            padding: 14px 0;

            @media screen and (max-width: 480px) {
              padding: 8px 0;
            }

            a {
              padding: 6px 20px;
              margin: auto;
              border: 1px solid $primary-color;
              border-radius: 2px;
              background-color: $primary-color;
              color: #FFF;

              &:hover {
                background-color: #c13736;
                border: 1px solid #c13736;
              }

              &.transparent-button {
                color: $primary-color;
                background-color: #FFF;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px transparent;
                overflow: hidden;
                -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
                -webkit-transition-property: color, background-color;
                transition-property: color, background-color;


                &:hover {
                  color: #FFF;
                  background-color: $primary-color;
                  border: 1px solid $primary-color;
                 }
              }
            }

            &:first-child {
              padding-right: 13px;
            }
          }
        }
      }
    }

    .navbar-nav.navbar-right.navbar-account {
      float: right !important;

      #credits {
        // display: inline-block;
        display: table-cell;
        vertical-align: middle;
        // margin-left: 5px;
        padding-left: 5px;

        @media screen and (max-width: 480px) {
          margin-left: 0;
        }

        .credits-container {
          display: table;

          .credit-content {
            height: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
            background: #f7f8f9;
            border: 1px solid #ebeef1;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            padding: 0 10px;
            margin: 17px 0;
            display: table-cell;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 130px;

            @media screen and (max-width: 480px) {
              max-width: 100px;
              margin-left: 0;
            }

            i {
              color: #f7bc34;
              font-size: 11px;
              line-height: 30px;
            }

            .value {
              font-size: 11px;
              color: #5b6670;
              font-weight: normal;
              line-height: 30px;
            }

            .phrase {
              font-size: 11px;
              color: #5b6670;
              font-weight: 200;
              text-transform: initial;
              line-height: 30px;
            }
          }

          a.buy-button {
            background: none;
            border: none;
            display: table-cell;
            padding: 0;

            .button-container {
              line-height: 35px;
              background-color: $primary-color;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              padding: 0 5px;
              font-size: 12px;
              text-transform: initial;
              color: #FFF;
            }

            &:hover {

              .button-container {
                background-color: #c13736;
              }
            }
          }
        }
      }

      .account-buttons {
        border-left: 1px solid #d0d3d5;
        padding: 0;
        padding-left: 5px;
        display: table;

        @media screen and (max-width: 860px) {
          border-left: none;
        }

        .dropdown {
          background: none;
          border: none;
          box-shadow: none;
          // display: inline-block;
          // display: table-cell;
          vertical-align: middle;
          max-height: 65px;

          @media screen and (max-width: 480px) {
            max-height: 50px;
          }

          button {
            background: none;
            border: none;
            box-shadow: none;
            letter-spacing: initial;
            padding: 0;
            max-height: 65px;
            min-height: 65px;


            &:hover {
              background: #f7f8f9;
            }

            @media screen and (max-width: 480px) {
              min-height: 50px;
              max-height: 50px;
            }

            .table {
              display: table;
              vertical-align: middle;
              max-height: 65px;
              min-height: 65px;
              margin-bottom: 0;

              @media screen and (max-width: 480px) {
                min-height: 50px;
                max-height: 50px;
              }

              .profile-pic {
                display: table-cell;
                vertical-align: middle;
                padding: 0 10px;

                .image-container {
                  background-image: url('/images/pages/index/profile-default.png');
                  background-size: cover;
                  background-position: center;
                  height: 33px;
                  width: 33px;
                }
              }

              .profile-detail {
                display: table-cell;
                vertical-align: middle;
                text-align: left;
                width: 145px;
                max-width: 145px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @media screen and (max-width: 1030px) {
                  display: none;
                }

                .logged {
                  font-size: 11px;
                  color: #5b6670;
                  font-weight: normal;
                  letter-spacing: initial;
                  line-height: 1.2;
                }

                .account-name {
                  color: #5b6670;
                  font-size: 9px;
                  font-weight: 200;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }

              .caret-container {
                display: table-cell;
                vertical-align: middle;
                padding-right: 5px;
                padding-left: 10px;

                @media screen and (max-width: 1030px) {
                  padding-left: 5px;
                }

                @media screen and (max-width: 768px) {
                  display: none;
                }

                i {
                  font-size: 10px;
                  color: #5b6670;
                }
              }
            }
          }

          .dropdown-menu {
            top: 95%;
            box-shadow: 0 0px 4px rgba(0,0,0,0.2);
            padding: 5px 0;
            min-width: 220px;

            @media screen and (max-width: 860px) {
              display: none;
            }

            li {
              font-size: 11px;
              color: #5b6670;
              background: none;
              padding: 0;
              text-transform: initial;
              letter-spacing: initial;
              font-weight: normal;
              width: 100%;

              &.divider {
                background-color: #e9eaeb;
                margin-bottom: 5px;
                margin-top: 5px;
              }

              &.header-title {
                padding: 7px 15px;
                font-size: 8px;
                color: #7c868e;
                text-transform: uppercase;
              }

              a {
                background: none;
                padding: 0;
                border: none;
                color: #5b6670;
                text-transform: initial;
                letter-spacing: initial;
                font-weight: normal;

                .item-container {
                  background: none;
                  padding: 7px 15px;
                  position: relative;


                  &.gold-item {
                    color: #e59c24;
                  }

                  .count {
                    position: absolute;
                    background: #3d97b5;
                    padding: 4px 7px;
                    height: 18px;
                    line-height: 10px;
                    border-radius: 8px;
                    top: 0;
                    right: 12px;
                    bottom: 0;
                    margin: auto;
                    font-size: 11px;
                    color: #FFF;
                  }
                }

                &:hover {
                  color: #1a1a1a;

                  .item-container {
                    background: #f6f8fa;
                  }
                }
              }

              &.accounts-item {
                a {

                  &:hover {
                    .item-table {
                      background: #f6f8fa;
                    }
                  }

                  .item-table {
                    display: table;
                    vertical-align: middle;
                    padding: 5px 15px;
                    width: 100%;

                    .profile-image {
                      display: table-cell;
                      vertical-align: middle;
                      width: 26px;

                      .image-container {
                        height: 26px;
                        width: 26px;
                        border-radius: 26px;
                        background-size: cover;
                        background-position: center;
                      }
                    }

                    .profile-detail {
                      display: table-cell;
                      vertical-align: middle;
                      padding-left: 8px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      max-width: 140px;
                    }

                    .caret-container {
                      display: table-cell;
                      vertical-align: middle;
                      text-align: right;
                      width: 50px;

                      i {
                        font-size: 9px;
                        color: #c1c6c9;
                      }
                    }
                  }
                }
              }

              &.icon-right {
                &:hover {
                  .item-table {
                    background: #f6f8fa;
                  }
                }

                .item-table {
                  display: table;
                  vertical-align: middle;
                  padding: 7px 15px;
                  width: 100%;

                  .text {
                    display: table-cell;
                    vertical-align: middle;
                    width: 180px;
                  }

                  .caret-container {
                    display: table-cell;

                    i {
                      font-size: 12px;
                      color: #c1c6c9;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#footer {
  background-color: #FFF;
  border-top: 1px solid #e9eaeb;
  text-align: center;
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0;

  .footer-table {
    display: table;
    min-height: 108px;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .kickstart-logo {
    display: table-cell;
    margin: auto;
    width: 36%;
    text-align: left;
    vertical-align: middle;
    padding-top: 15px;
    padding-bottom: 15px;

    .copyright-in {
      font-size: 9px;
      color: #5b6670;
      opacity: 0.5;
      display: none;
      padding-top: 10px;

      @media screen and (max-width: 1000px) {
        display: block;
      }
    }

    img {
      @media screen and (max-width: 1000px) {
        width: 150px;
      }
    }

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (max-width: 480px) {
      display: block;
      text-align: center;
      margin-top: 10px;
    }
  }

  .copyright {
    display: table-cell;
    font-size: 11px;
    color: #5b6670;
    opacity: 0.5;
    width: 20%;
    vertical-align: middle;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .footer-nav-right {
    display: table-cell;
    width: 36%;
    vertical-align: middle;
    padding-left: 0;

    @media screen and (max-width: 1000px) {
      width: auto;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }

    li {
      float: right;
      display: block;
      font-size: 11px;
      text-transform: uppercase;
      color: #5b6670;
      padding: 0 10px;

      @media screen and (max-width: 1000px) {
        font-size: 9px;
      }

      &:first-child {
        padding-right: 0;
      }

      &:hover {
        color: $primary-color;
      }

      &.active {
        color: $primary-color;
      }
    }

    &.mobile-footer {
      display: none;
      text-align: center;

      @media screen and (max-width: 480px) {
        display: block;
      }

      li {
        padding: 0 3px;
        float: none;
        display: inline-block;
      }
    } 
  }
}

#pageHeadline {
  padding: 50px 0;
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #da382f; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #da382f , #ee9f16); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #da382f, #ee9f16); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #da382f, #ee9f16); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #da382f , #ee9f16); /* Standard syntax */
  color: #FFF;
  
  @media screen and (max-width: 480px) {
    padding: 25px 0;
  }

  .header {
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    line-height: 1.2;

    @media screen and (max-width: 480px) {
      font-size: 24px;
    }
  }

  .sub {
    font-weight: 200;
    font-size: 17px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.modal {
  padding: 0 !important;

  .modal-semaphore {

    &.modal-md {
      width: 475px;

      @media screen and (max-width: 480px) {
        width: auto;
      }
    }
    
    .modal-content {
      border: none;
      border-radius: 6px;

      .modal-header {
        border: none;
        padding-bottom: 0;

        h4 {
          color: $primary-color;
          padding: 0 25px;
          padding-top: 20px;
          font-size: 18px;
          letter-spacing: initial;
          text-transform: initial;
          line-height: 1.4;

          @media screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 1.2;
            padding: 0 20px;
            padding-left: 0;
          }
        }

        .close {
          font-size: 15px;
          color: #c3c8cb;

          &:hover {
            color: #5b6670;
          }
        }
      }

      .modal-body {
        padding: 25px 40px;
        padding-bottom: 0;
        font-size: 11px;

        @media screen and (max-width: 480px) {
          padding: 15px;
        }

        #error-container {
          .row {
            margin: 0;
            margin-bottom: 10px;

            .alert {
              padding: 10px;
              background: $primary-color;

              h4 {
                font-size: 12px;
                font-weight: normal;
                margin-bottom: 5px;
              }

              ul {
                padding: 0;
                font-size: 10px;
                font-weight: 200;
                line-height: 1.3;
              }
            }
          }
        }

        .form-group {
          margin-left: 0;
          margin-right: 0;
          letter-spacing: initial;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: normal;
            color: #5b6670;
            padding: 0;
            letter-spacing: initial;
            text-align: left;

            .sub {
              display: block;
              font-size: 9px;
              color: #808b91;
              margin-top: 3px;
              margin-bottom: 5px;
              line-height: 1.2;
            }
          }

          input {
            // box-shadow: none;
            // border: 1px solid #d0d3d5;
            // border-radius: 2px;
            font-size: 12px;
            color: #5b6670;
            padding: 6px 12px;
            letter-spacing: initial;

            @media screen and (max-width: 480px) {
              font-size: 10px;
            }

            &:hover {
              // border: 1px solid #a0a2a2;
            }
          }

          select {
            // box-shadow: none;
            // border: 1px solid #d0d3d5;
            // border-radius: 2px;
            font-size: 12px;
            color: #5b6670;
            padding: 6px 12px;
            letter-spacing: initial;

            @media screen and (max-width: 480px) {
              font-size: 10px;
            }

            &:hover {
              // border: 1px solid #a0a2a2;
            }
          }

          textarea {
            // box-shadow: none;
            // border: 1px solid #d0d3d5;
            // border-radius: 2px;
            font-size: 12px;
            color: #5b6670;
            padding: 6px 12px;
            letter-spacing: initial;
            resize: none;

            @media screen and (max-width: 480px) {
              font-size: 10px;
            }

            &:hover {
              // border: 1px solid #a0a2a2;
            }
          }

          &.icon-right {
            position: relative;

            input {
              padding-right: 35px;
            }

            i {
              position: absolute;
              right: 10px;
              bottom: 10px;
              margin: auto;
              font-size: 17px;
              color: #5b6670;
              cursor: pointer;

              &:hover {
                color: #1e1e1e;
              }
            }
          }

          &.checkbox-group {

            input {
              padding: 0;
              border: none;
              border-color: #4297b3;
              color: #4297b3;

              &:after {
                border-color: #4297b3;
              }

              &:checked:after {
                background-color: #4297b3;
                border-color: #4297b3;
              }
            }

            .link-stick {
              a {
                font-weight: normal;
                font-size: 10px;
                color: #3d97b5;
                padding: 0 13px;


                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .modal-footer {
        border: none;
        padding: 30px 40px;
        padding-top: 0;

        @media screen and (max-width: 480px) {
          padding: 15px;
        }

        .button-submit {
          display: block;
          width: 100%;
          margin: 0;
          font-size: 12px;
          margin-top: 25px;
          padding: 10px 25px;
          line-height: 1.4;

          @media screen and (max-width: 480px) {
            margin-top: 0;
          }

          &:active, &:focus {
            box-shadow: none;
          }
        }

        .text-center {
          font-size: 11px;
          padding-top: 25px;
          color: #1e1e1e;

          @media screen and (max-width: 480px) {
            padding-top: 10px;
          }

          a {
            color: #4297b3;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

#deleteUserModal {
  .modal-content {
    .modal-body {
      & * { 
        font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: 12px;
      }
    }
    .modal-footer {
      padding-top: 0;
      .button-submit {
        padding: 5px 25px;
      }

      .button-transparent {
        background: none;
        border: none;
        box-shadow: none;
        font-size: 12px; 
        color: #c1c6c9;
        padding: 5px 25px;

        &:hover {
          color: #1e1e1e;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-semaphore {
    width: 387px;
  }
}


#homeGuestPage {
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  
  .hero-container {
    background-image: url('/images/pages/index/texture-gray.jpg');
    background-size: 200px;
    background-repeat: repeat;

    .gradient-back {
      background: transparent; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(rgba(152, 152, 152, 0.1) 55%, #fafbfc); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(rgba(152, 152, 152, 0.1) 55%, #fafbfc); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(rgba(152, 152, 152, 0.1) 55%, #fafbfc); /* For Firefox 3.6 to 15 */
      background: linear-gradient(rgba(152, 152, 152, 0.1) 60%, #fafbfc); /* Standard syntax */
    }
    .hero-unit {
      margin: auto;
      display: table;
      width: 100%;
      max-width: 850px;
      padding-top: 45px;
      padding-bottom: 45px;
      padding-left: 0;
      padding-right: 0;
      
      @media screen and (max-width: 768px) {
        display: block;
        text-align: center;
      }

      .image {
        display: table-cell;
        width: 395px;
        text-align: center;
        vertical-align: middle;

        @media screen and (max-width: 768px) {
          display: block;
          margin: auto;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
        }

        img {
          max-width: 275px;
          margin: auto;

          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }

      .content {
        display: table-cell;
        vertical-align: middle;

        @media screen and (max-width: 768px) {
          display: block;
        }

        .header {
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 700;
          font-size: 53px;
          color: $primary-color;
          text-transform: uppercase;
          line-height: 45px;
          padding-bottom: 10px;

          @media screen and (max-width: 480px) {
            font-size: 40px;
            line-height: 38px;
          }
        }

        .sub {
          font-size: 18px;
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 200;
          padding-bottom: 30px;

          @media screen and (max-width: 480px) {
            font-size: 14px;
          }

          a {
            color: #e59c24;
            @media screen and (max-width: 480px) {
              display: block;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .home-features {
    background-color: #FFF;
    box-shadow: 0 0 10px #d0d3d5;
    border: 1px solid #d0d3d5;
    border-radius: 5px;
    padding: 55px 115px;
    margin-bottom: 70px;

    @media screen and (max-width: 768px) {
      padding: 25px;
    }

    .headline {
      text-align: center;
      padding: 0 45px;
      padding-bottom: 20px;

      @media screen and (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 30px;
      }

      .header {
        font-size: 27px;
        padding-bottom: 10px;

        @media screen and (max-width: 480px) {
          font-size: 20px;
        }
      }

      .sub {
        font-size: 17px;
        color: #5b6670;
        font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 200;

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }

    .features-tabs {
      padding-top: 50px;

      @media screen and (max-width: 768px) {
        padding-top: 0;
      }

      .table-display {
        display: table;

        @media screen and (max-width: 768px) {
          padding-bottom: 15px;
        }

        @media screen and (max-width: 480px) {
          display: block;
        }

        .image {
          display: table-cell;
          vertical-align: top;
          width: 80px;

          @media screen and (max-width: 480px) {
            display: block;
            margin: auto;
          }

          img {
            width: 100%;
            padding: 0 15px;
            padding-top: 5px;
          }
        }

        .content {
          display: table-cell;
          vertical-align: top;
          padding-left: 15px;

          @media screen and (max-width: 480px) {
            display: block;
            font-size: 20px;
            text-align: center;
            padding-left: 0;
            padding-top: 5px;
          }

          .header {
            font-size: 17px;

            @media screen and (max-width: 480px) {
              font-size: 14px;
            }
          }

          .sub {
            font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 200;
            font-size: 14px;
            color: #5b6670;

            @media screen and (max-width: 480px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .home-pricing {
    background-color: $primary-color;
    padding: 45px 0;

    @media screen and (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .home-pricing-container {
      max-width: 675px;
      margin: auto;
      color: #FFF;
      text-align: center;

      .headline {
        padding-bottom: 35px;

        .header {
          font-size: 26px;
          padding-bottom: 5px;

          @media screen and (max-width: 480px) {
            font-size: 18px;
            line-height: 1.2;
          }
        }

        .sub {
          font-size: 17px;
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 200;

          @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 1.2;
          }
        }
      }

      .prices {
        .col {
          padding: 0 70px;

          @media screen and (max-width: 768px) {
            padding-bottom: 20px;
          }
        }

        .image {
          img {
            width: 63px;
          }
        }

        .header {
          font-size: 17px;
          padding-top: 10px;
          padding-bottom: 10px;

          @media screen and (max-width: 768px) {
            padding-bottom: 5px;
            padding-top: 5px;
          }

          @media screen and (max-width: 480px) {
            font-size: 14px;
            padding-bottom: 0;
          }
        }

        .sub {
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 200;
          font-size: 14px;

          @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 1.2;
          }

          .bold {
            font-weight: 700;
          }
        }
      }
    }
  }

  .home-about {
    background-color: #FFF;
    padding: 60px 0;

    .about-table {
      display: table;

      @media screen and (max-width: 768px) {
        display: block;
      }

      .image {
        display: table-cell;
        padding-top: 8px;

        @media screen and (max-width: 768px) {
          display: block;
        }

        img {
          width: 125px;

          &.kickstart {
            padding: 30px 0;

            @media screen and (max-width: 768px) {
              padding-left: 20px;
            }
          }
        }
      }

      .content {
        display: table-cell;
        vertical-align: top;
        padding-left: 55px;
        text-align: justify;

        @media screen and (max-width: 768px) {
          display: block;
          padding-left: 0;
          padding-top: 15px;
        }

        .header {
          font-size: 26px;
          padding-bottom: 20px;

          @media screen and (max-width: 480px) {
            font-size: 20px;
            padding-bottom: 10px;
          }
        }

        .sub {
          font-size: 14px;
          font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 200;
          color: #5b6670;
          line-height: 1.6;

          @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 1.2;
          }

          p {
            @media screen and (max-width: 480px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .customers {
    text-align: center;
    padding: 60px 0;

    .header {
      font-size: 17px;
      opacity: 0.5;
    }

    .image {
      img {
        max-width: 787px;
        width: 100%;
      }
    }

    .join-us-button {
      width: 100%;
      font-size: 26px;
      border-radius: 8px;
      text-transform: initial;
      margin-top: 40px;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        width: auto;
      }
      @media screen and (max-width: 480px) {
        padding: 10px 25px;
        border-radius: 2px;
        font-size: 14px;
      }
    }
  }
}

section {
  padding: 35px 0;
  padding-top: 0;

  &:first-child {
    // padding-top: 15px; 

    @media screen and (max-width: 480px) {
      padding-top: 0;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }

  h4 {
    font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 17px;
    text-transform: initial;
    letter-spacing: initial;
    color: #1e1e1e;
  }

  .section-box {
    border: 1px solid #d0d3d5;
    border-radius: 5px; 
    padding: 35px;
    margin-top: 20px;

    @media screen and (max-width: 480px) {
      padding: 15px;
    }

    h4 {
      font-size: 14px;
    }

    p {
      font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 200;
      text-transform: initial;
      letter-spacing: initial;
      font-size: 14px;
      color: #5b6670;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }


      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #3d97b5;
        font-weight: normal;
        font-size: 14px;

        @media screen and (max-width: 480px) {
          font-size: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.collapse-box {
      padding: 0;
      overflow: hidden;

      .panel-group {
        margin: 0;

        .panel {
          box-shadow: none;
          border: none;
          border-radius: 0;
          background: transparent;
          border-bottom: 1px solid #d0d3d5;
          margin: 0;

          &:last-child {
            border-bottom: none;
          }
          
          &:hover {
            background: #fafbfc;

            
          }

          .panel-heading {
            background: transparent;
            padding: 0;

            .heading-container {
              padding: 35px;
              padding-right: 65px;
              position: relative;
              -webkit-transition: all .200s;
              -moz-transition: all .200s;
              transition: all .200s;

              @media screen and (max-width: 480px) {
                padding: 15px;
                padding-right: 40px;
              }

              i {
                position: absolute;
                top: 32px;
                right: 35px;
                font-size: 20px;
                color: #c1c6c9;

                @media screen and (max-width: 480px) {
                  font-size: 14px;
                  right: 15px;
                  top: 15px;
                }

              }

              h4 {
                margin: 0;
              }
            }
          }

          .panel-collapse {
            background: transparent;
            border: none;
            box-shadow: none;

            .panel-body {
              padding: 35px;
              padding-top: 0;
              border: none;
              margin-top: 0;

              @media screen and (max-width: 480px) {
                padding: 15px;
              }
            }
          }

          &.collapse-shown {
            .panel-heading {
              .heading-container {
                padding-bottom: 15px;
                -webkit-transition: all .200s;
                -moz-transition: all .200s;
                transition: all .200s;
                h4 {
                  color: #3d97b5;
                }
              }
            }
          }
        }
      }
    }
  }
}

.account-settings-page {
  #sidebar {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    &.affix {
      @media screen and (max-width: 768px) {
        position: relative;
        top: 0 !important;
      }
    }
  }

  &.sidebar-table {
    #mainCol {
      @media screen and (max-width: 480px) {
        padding: 0;
      }
    }
  }
}

.sidebar-table {

  .sidebar-content {
    padding-top: 40px;
    font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    &.more-top-padding {
      padding-top: 50px;

      @media screen and (max-width: 768px) {
        padding-top: 25px;
      }
    }

    &.hide-on-mobile {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    #sidebar {
      top: 30px;
    }

    .header {
      font-size: 12px;
      text-transform: uppercase;
      color: #c1c6c9;
      padding-bottom: 10px;
    }

    .profile-info {
      border-bottom: 1px solid #d0d3d5;
      padding-bottom: 25px;
      margin-bottom: 5px;

      .profile-image {
        padding-bottom: 10px;

        .image-container {
          margin: auto;
          height: 80px;
          width: 80px;
          border-radius: 50%;
          background-image: url('/images/pages/index/profile-default.png');
          background-size: cover;
          background-position: center;
        }
      }
      .profile-name {
        text-align: center;
        font-size: 17px;
        color: #1e1e1e;
        font-weight: normal;
      }
    }

    ul {
      color: #7c868e;

      li {
        border: none;
        padding: 0;

        &.active {
          background: none;

          a {
            .item-container {
              background-color: #f6f8fa;
              color: #5b6670;
            }
          }
        }

        a {

          .item-container {
            padding: 10px 20px; 
          }

          &:hover, &.active {
            .item-container {
              background-color: #f6f8fa;
              color: #5b6670;
            }
          }
        }
      }
    }
  }
}

.docs-page {
  .panel {
    background: #fef5f5;
    box-shadow: none;
    border: none; 
    border-radius: 8px;
    overflow: auto;

    &:last-child {
      margin-bottom: 0;
    }

    .panel-heading {
      background-color: $primary-color;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 8px 35px;

      @media screen and (max-width: 480px) {
        padding: 5px 15px;
      }

      .panel-title {

        h4 {
          letter-spacing: initial;
          font-size: 15px;
          text-transform: initial;

          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
        }
      }
    }

    .panel-body {
      padding: 25px 35px;

      @media screen and (max-width: 480px) {
        padding: 15px;
        overflow: auto;
      }

      p {
        font-size: 14px;
        color: #1e1e1e;
        letter-spacing: initial;

        @media screen and (max-width: 480px) {
          font-size: 10px;
        }

        a {
          color: #3d97b5;
          @media screen and (max-width: 480px) {
            font-size: 10px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ol {
        @media screen and (max-width: 480px) {
          padding-left: 15px;
        }

        li {
          padding-left: 10px;
          font-style: initial;

          @media screen and (max-width: 480px) {
            padding-left: 5px;
            font-size: 10px;
          }
        }
      }
    }

    &.panel-clear {
      background: none;

      .panel-heading {
        background: none;
        padding-left: 0;

        .panel-title {
          * {
            font-size: 26px;
            color: #1e1e1e;
            letter-spacing: initial;
            text-transform: initial;

            @media screen and (max-width: 480px) {
              font-size: 18px;
            }
          }
        }
      }

      .panel-body {
        padding: 35px;
        border: 1px solid #d0d3d5;
        border-radius: 8px;

        @media screen and (max-width: 480px) {
          padding: 15px;
        }

        &.panel-no-padding {
          padding: 0;
        }

        .nav {
          padding: 0 25px;
          border-bottom: 1px solid #d0d3d5;
          max-height: 59px;
          overflow: hidden;

          @media screen and (max-width: 480px) {
            padding: 0;
          }

          li {

            @media screen and (max-width: 768px) {
              display: table-cell;
              width: 1%;
            }

            a {
              border-bottom: 0;
              box-shadow: none;
              font-size: 17px;
              color: #7c868e;
              padding-top: 15px;
              padding-bottom: 15px;
              border-radius: 0;

              @media screen and (max-width: 480px) {
                font-size: 12px;
                padding: 0;
                padding-top: 15px;
                padding-bottom: 15px;
                margin: 0;
              }
              
              &:hover {
                color: $primary-color;
                padding-bottom: 10px;
                border-bottom: 3px solid $primary-color;

                @media screen and (max-width: 480px) {
                  padding-bottom: 8px;
                }
              }
            }

            &.active {
              a {
                color: $primary-color;
                padding-bottom: 10px;
                border-bottom: 3px solid $primary-color;

                @media screen and (max-width: 768px) {
                  padding-bottom: 8px;
                }
              }
            }
          }
        }

        .tab-content {
          padding: 35px;

          @media screen and (max-width: 480px) {
            padding: 15px;
          }

          .tab-pane {
            p {
              color: #5b6670;
              font-size: 14px;

              @media screen and (max-width: 480px) {
                font-size: 12px;
              }

              a {
                color: #3d97b5;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            pre {
              border: none;
              padding: 0;
              background: none;
              margin: 0;

              code {
                padding: 0 15px;
                color: #5b6670;
                font-size: 14px;
                background: #f6f8fa;
                border-radius: 8px;

                @media screen and (max-width: 480px) {
                  font-size: 12px;
                }

                .hljs-keyword,
                .css .rule .hljs-keyword,
                .hljs-winutils,
                .javascript .hljs-title,
                .nginx .hljs-title,
                .hljs-subst,
                .hljs-request,
                .hljs-status {
                  color: #5b6670;
                }

                .hljs-string,
                .hljs-tag .hljs-value,
                .hljs-phpdoc,
                .tex .hljs-formula {
                  color: $primary-color
                }

                .hljs-title,
                .hljs-id,
                .coffeescript .hljs-params,
                .scss .hljs-preprocessor {
                  color: $primary-color;
                  font-weight: bold
                }

                .hljs-comment,
                .hljs-template_comment,
                .diff .hljs-header,
                .hljs-javadoc {
                  color: #c1c6c9;
                  font-style: italic
                }
              }
            }
          }
        }

        h4 {
          letter-spacing: initial;
          text-transform: initial;
          font-size: 17px;
          color: #1e1e1e;

          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
        }

        p {
          font-size: 14px;
          color: #5b6670;
          letter-spacing: initial;

          @media screen and (max-width: 480px) {
            font-size: 10px;
          }

          a {
            color: #3d97b5;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        
        .btn {
          box-shadow: none;
          color: #FFF;
          font-weight: normal;
          font-size: 12px;
          padding: 5px 8px;
          box-shadow: none;
          border: none;

          &.btn-success {
            background: #00b09c;

            &:hover {
              background: #017f71;
            }
          }

          &.web-tool {
            margin-left: 0;
            margin-top: 5px;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .table  {
          padding-top: 20px; 
          border: 1px solid #d0d3d5;
        
          th {
            background: none;
            color: #7c868e;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: normal;
            border: none;
            padding: 15px 25px;
            border-bottom: 2px solid #d0d3d5;
          }

          td {
            padding: 12px 25px;
            font-size: 12px; 
            color: #5b6670;
            font-weight: 200;
            line-height: 1.5;
            border: none;

            @media screen and (max-width: 480px) {
              font-size: 10px;
            }

            a {
              color: #3d97b5;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          tr {
            &:nth-child(odd) {
              td {
                background: #f8fbfd;
              }
            }
          }
        }
      }
    }
  } 
}

.container-table {
  display: table;
  width: 1000px;


  @media screen and (max-width: 1000px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

#apiKeyBar {
  z-index: 10 !important;
  width: 100%;
  margin: auto;
  // margin-top: -1px;
  text-align: center;

  .container-table {
    padding-top: 11px;
    padding-bottom: 10px;
    margin: auto;
  }
  
  .input-table {
    display: table-cell;
    width: 100%;

    .input-group {
      position: relative;
      width: 100%;
      padding-right: 15px;

      i {
        position: absolute;
        left: 20px;
        top: 0;
        font-size: 13px;
        color: #FFF;
        line-height: 30px;
      }

      .label {
        padding: 0;
        top: 0;
        left: 45px;
        position: absolute;
        line-height: 30px;
        font-weight: normal;
        font-size: 12px;
        @media screen and (max-width: 480px) {
          font-size: 10px;
        }
      }

      input {
        background: #49525a;
        font-weight: 200;
        color: #FFF;
        border-radius: 2px;
        border: none;
        box-shadow: none;
        font-size: 12px;
        padding: 4px 10px;
        padding-left: 125px;
        width: 100%;
        letter-spacing: 1.3px;

        @media screen and (max-width: 480px) {
          font-size: 10px;
          padding-left: 100px;
        }
      }
    }
  }

  .button-table {
    display: table-cell;

    a {
      .button-container {
        width: 170px;
        color: #FFF;
        background-color: #3d97b5;
        border-radius: 2px;
        padding: 8px 20px;
        font-size: 9px;
        font-weight: normal;
        text-transform: uppercase;
        @media screen and (max-width: 480px) {
          width: 100%;
          max-height: 32px;
          max-width: 50px;
          padding: 8px 18px;
        }
        i {
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
        }
        span {
          @media screen and (max-width: 480px) {
            display: none;
          }
        }
      }

      &:hover {
        .button-container {
          background-color: #2e738a;
        }
      }

      &:active {
        .button-container {
          background-color: #235769;
        }
      }
    }
  }
}

#quickLink {
  margin-top: -1px;
  padding: 30px 0;
  padding-top: 31px;
  background-image: url('/images/pages/index/dashboard-back.jpg');
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 480px) {
    padding: 15px 0;
    padding-top: 16px;
  }

  .header {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: normal;
    color: #FFF;
    border-bottom: 1px solid #6c7682;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  .quicklink-row {
    margin-bottom: 12px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .link-container {
    background-color: rgba(250, 251, 252, 0.1); 
    border-radius: 2px;
    border: none;
    margin: 0;
    display: table;
    padding: 20px;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
    }

    .link-button-container {
      display: table-cell;
      min-width: 195px;
      vertical-align: middle;

      @media screen and (max-width: 768px) {
        width: 195px;
      }

      @media screen and (max-width: 480px) {
        display: block;
        width: 100%;
      }

      a {
        .link-button {
          display: table;
          min-height: 45px;
          background: #da382f;
          width: 100%;

          .icon {
            display: table-cell;
            background: $primary-color;
            vertical-align: middle;
            width: 45px;
            text-align: center;
            color: #FFF;
            font-size: 17px;
          }

          .title {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            color: #FFF;
            font-size: 13px;
            font-weight: normal;
            padding: 11px 12px;
          }
        }

        &:hover {
          .link-button {
            background: $primary-color;
            
          }
        }

        &:active {
          .link-button {
            background: #a02e2d;
          }
        }
      }
    }

    .text-container {
      padding: 5px 20px;
      padding-right: 0;
      display: table-cell;
      vertical-align: middle;
      color: #FFF;
      font-size: 12px;
      font-weight: 200;
      line-height: 1.4;
      @media screen and (max-width: 480px) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}

#verifyNotifBar {
  background-color: #f9be19;
  z-index: 10 !important;
  width: 100%;

  .verification-container {
    display: table; 
    padding: 5px 0;
    width: 1000px;

    @media screen and (max-width: 1110px) {
      padding-right: 15px;
      padding-left: 15px;
      width: 100%;
    }

    @media screen and (max-width: 480px) {
      display: block;
    }

    .text {
      display: table-cell;
      width: 100%;
      font-size: 10px;
      font-weight: 200;
      color: #1e1e1e;
      vertical-align: middle;
      position: relative;
      padding-left: 28px;
      @media screen and (max-width: 480px) {
        display: block;
      }

      i {
        position: absolute;
        height: 17px;
        top: -3px;
        bottom: 0;
        margin: auto;
        left: 0;
        font-size: 17px;
        color: #c09224;
      }
    }

    .button-container {
      display: table-cell;
      min-width: 195px;
      vertical-align: middle;
      @media screen and (max-width: 480px) {
        display: block;
        margin-top: 5px;
      }

      a {
        .button-verification {
          background-color: #ffd633;
          border-radius: 2px;
          font-size: 9px;
          text-transform: uppercase;
          font-weight: normal;
          padding: 10px 20px;
          text-align: center;
        }

        &:hover {
          .button-verification {
            background-color: #fbdf72;
          }
        }

        &:active {
          .button-verification {
            background-color: #f9cc1b;
          }
        }
      }
    }
  }
}

.section-container {
  margin-top: 40px;
  margin-bottom: 50px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;

    .panel {
      margin-bottom: 0;
    }
  }

  &.less-margin-top {
    margin-top: 10px;
  }

  .section-header {
    font-size: 26px;
    font-weight: normal;
    color: #1e1e1e;
    margin-bottom: 25px;

    @media screen and (max-width: 480px) {
      margin-bottom: 10px;
      font-size: 20px;
    }

    &.link-right {
      .link {
        float: right;
        font-size: 12px;
        color: #a3a9ae;
        line-height: 47px;
        font-weight: 200;

        @media screen and (max-width: 480px) {
          float: none;
          display: block;
          line-height: 1;
          padding-bottom: 10px;
          font-size: 10px;
        }

        &:hover {
          text-decoration: underline;
        }

        &.button-link {
          color: #FFF;
          font-weight: normal;
          line-height: 1;
          margin-top: 6px;

          i {
            font-size: 14px;
            padding-right: 5px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .sub {
      font-weight: 200;
      font-size: 14px;
      color: #7c868e;

      @media screen and (max-width: 480px) {
        font-size: 10px;
      }
    }
  }

  .panel {
    border: 1px solid #d0d3d5;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    overflow: hidden;

    .panel-body {
      padding: 0;

      .default-form-tab {
        .form-group {
          margin-left: 0;
          margin-right: 0;

          span.help-block {
            font-size: 10px;
            margin-top: 0;
            margin-bottom: 0;
          }

          .control-label {
            text-align: left;
          }

          a {
            font-size: 10px;
            font-weight: normal;
            font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
            letter-spacing: initial;
            padding-top: 7px;
            color: #3d97b5;

            &:hover {
              color: #337c95;
              text-decoration: underline;
            }
          }

          .selectize-control {
            padding: 0 !important;
            border: none !important;
            min-height: 0 !important;

            .selectize-input {
              width: 100% !important;
              font-weight: 200;
              font-size: 12px;
              color: #1e1e1e;
              border: 1px solid #c1c6c9;
              border-radius: 2px;
              box-shadow: none;
              padding: 3px 15px;
              padding-left: 10px;
              padding-bottom: 5px;
              height: auto;
              min-height: 34px;
              resize: none;
            }

            input {
              font-size: 12px;
              margin-left: 5px;

              &:focus {
                -webkit-box-shadow: inset 0 -1px 0 $primary-color;
                box-shadow: inset 0 -1px 0 $primary-color;
              }
            }

            .item {
              padding: 1px 3px;
              padding-bottom: 2px;
              width: auto;
              display: inline-block;
              color: #FFF;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              background-color: $primary-color;
              background-repeat: repeat-x;
              line-height: 1.3;
              margin-right: 5px;

              .remove {
                font-size: 15px;
                padding-left: 5px;
                color: #FFF;

                &:hover {
                  text-decoration: none;
                }
              }
            }

            .selectize-dropdown {
              min-height: 25px !important;
              padding: 3px 10px !important;
              font-size: 10px;

              * {
                font-size: 10px !important;
              }
            }
          }
        }
      }

      .nav-tabs {
        border-bottom: 1px solid #d0d3d5;
        padding: 0 25px;

        @media screen and (max-width: 480px) {
          padding: 0;
        }

        li {
          @media screen and (max-width: 480px) {
            float: left;
          }

          a {
            padding: 15px 50px;
            font-size: 17px;
            color: #7c868e;

            @media screen and (max-width: 480px) {
              padding: 15px 25px;
              font-size: 14px;
            }
          }

          &.active a, a:hover {
            box-shadow: inset 0 -2px 0 $primary-color;
            color: $primary-color;
          }
        }
      }

      .dataTables_wrapper {

        > .row {
          margin: 0;
          & > div {
            padding-left: 0;
            padding-right: 0;
          }
          &:first-child {
            background: #f6f8fa;
            padding: 8px 25px;
            font-weight: 200;
            font-size: 12px;
            color: #5b6670;
            @media screen and (max-width: 480px) {
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          &:nth-child(2) {
            //table-responsive
            margin: 0;
            overflow-x: auto;
            min-height: 0.01%;
            @media screen and (max-width: 767px) {
              width: 100%;
              overflow-y: hidden;
              -ms-overflow-style: -ms-autohiding-scrollbar;
              border: 1px solid #dddddd;
            }
          }
          &:last-child {
            font-weight: 200;
            font-size: 12px;
            color: #5b6670;
            padding: 10px 25px;

            @media screen and (max-width: 480px) {
              padding-left: 15px;
              padding-right: 15px;
              font-size: 10px;
            }
          }
        }
        .dataTables_length {
          label {
            font-weight: 200;
            letter-spacing: 0;
            margin: 0;
            width: 100%;

            select {
              font-size: 14px;
              font-weight: 700;
              border: 1px solid #d0d3d5;
              border-radius: 2px;
              padding: 6px 10px;
              padding-right: 24px;
              box-shadow: none;
              margin: 0 5px;
              height: auto;
              letter-spacing: 0;
              background: #FFF;
              max-width: 60px;
              display: inline-block;

              @media screen and (max-width: 480px) {
                font-size: 12px;
              }
            }
          }
        }
        .dataTables_filter {
          label {
            font-weight: 200;
            letter-spacing: 0;
            margin: 0;
            float: right;

            @media screen and (max-width: 768px) {
              float: left;
            }

            input {
              border: 1px solid #d0d3d5;
              border-radius: 2px;
              box-shadow: none;
              font-size: 14px;
              font-weight: 200;
              color: #5b6670;
              padding: 6px 15px;
              margin-left: 10px;
              background: #FFF;
              height: auto;

              @media screen and (max-width: 480px) {
                font-size: 12px;
              }

              &::-webkit-input-placeholder {
                  color: #c1c6c9;
              }
              &:-moz-placeholder {
              }
              &::-moz-placeholder {
                  color: #c1c6c9;
              }
              &:-ms-input-placeholder {
                  color: #c1c6c9;
              }
            }
          }
        }
        .dataTables_processing {
            font-size: 12px;
            font-weight: 200;
            color: #5b6670;
            padding: 7px 15px;
            border-radius: 0;
            border: none;
            box-shadow: none;
            text-align: center;
        }

        .dataTables_info {
          line-height: 38px;
        }
        .dataTables_paginate {
          .pagination {
            margin: 0;
            float: right;

            @media screen and (max-width: 768px) {
              float: left;
            }

            .paginate_button {
              a {
                font-size: 12px;
                font-weight: 200;
                padding: 7px 15px;
                border-left: 1px solid #d6d9db;
                border-top: 1px solid #d6d9db;
                border-bottom: 1px solid #d6d9db;
                color: #5b6670;
                @media screen and (max-width: 480px) {
                  font-size: 10px;
                  padding: 5px 10px;
                }

                &:hover {
                  color: $primary-color;
                }
              }

              &.active a {
                color: $primary-color;
                background: none;
                border-right: none;
              }

              &.disabled a {
                color: #bbbbbb;
                &:hover {
                  color: #bbbbbb;
                }
              }
            }
          }
        }

      }

      .tab-options {
        background: #f6f8fa;
        padding: 8px 25px;
        font-weight: 200;
        font-size: 12px;
        color: #5b6670;

        @media screen and (max-width: 480px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        &.graph-options {
          font-size: 17px;
          color: #7c868e;
          padding: 15px 25px;
          border-bottom: 1px solid #d0d3d5;
          font-weight: normal;
          line-height: 36px;

          @media screen and (max-width: 480px) {
            padding-left: 15px;
            padding-right: 15px;
          }

          canvas#usage {
            width: 100% !important;
          }

          .button-graph-container {
            display: table;

            .button-graph-page {
              display: table-cell;
              border: 1px solid #d0d3d5;
              box-shadow: none;
              margin: 0;
              background-color: #FFF;
              font-size: 12px;
              color: #5b6670;
              font-weight: 200;
              min-width: 100px;
              padding: 8px;
              line-height: initial;

              &:first-child {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                border-right: none;
              }

              &:last-child {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
              }

              &:hover {
                background: #efefef;
              }
            }
          }
        }

        &.on-top {
          border-radius: 2px;
          margin-top: 1px;
        }

        select {
          font-size: 14px;
          font-weight: 700;
          border: 1px solid #d0d3d5;
          border-radius: 2px;
          padding: 6px 10px;
          padding-right: 30px;
          box-shadow: none;
          margin: 0 5px;

          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
        }

        .form-group {
          margin: 0;
          border: none;
          box-shadow: none;
          position: relative;

          @media screen and (max-width: 768px) {
            margin-top: 5px;
          }

          input {
            width: 100%;
            border: 1px solid #d0d3d5;
            border-radius: 2px;
            box-shadow: none;
            font-size: 14px;
            font-weight: 200;
            color: #5b6670;
            padding: 4px 15px;

            @media screen and (max-width: 480px) {
              font-size: 12px;
            }

            &::-webkit-input-placeholder {
                color: #c1c6c9;
            }
            &:-moz-placeholder {
            }
            &::-moz-placeholder {
                color: #c1c6c9;
            }
            &:-ms-input-placeholder {
                color: #c1c6c9;
            }
          }

          i {
            position: absolute;
            top: -2px;
            right: 15px;
            bottom: 0;
            color: #5b6670;
            font-size: 17px;
            height: 17px;
            margin: auto;
          }
        }
      }

      .graph {
        padding: 30px;
        @media screen and (max-width: 480px) {
          padding: 15px;
        }
      }

      .tab-content {

        .upload-box {
          padding: 30px;

          .upload-area {
            background: #f8fbfd;
            border: 1px dashed #d0d3d5;
            border-radius: 3px;
            padding: 25px;
            text-align: center;

            &.uploaded {
              border: 1px solid #f8fbfd;
            }

            .title {
              color: #5b6670;
              font-size: 14px;
              font-weight: normal;
              margin-bottom: 10px;
            }

            .button-choose-file {
              font-size: 14px;
              color: #FFF;
              background: #5b6670;
              border-radius: 2px;
              box-shadow: none;
              border: none;
              padding: 8px 25px;

              &:hover {
                background: #454d55;
              }
            }

            .table-input-group {
              display: table;
              text-align: center;
              margin: auto;
              vertical-align: middle;

              .file-uploaded {
                display: table-cell;
                vertical-align: middle;
                padding-right: 10px;

                .input-box {
                  background: #FFF;
                  border: 1px solid #d0d3d5;
                  border-radius: 2px;
                  font-size: 14px;
                  font-weight: 200;
                  color: #5b6670;
                  padding: 4px 40px;
                  max-width: 300px;
                  min-width: 300px;
                  overflow: hidden;
                  position: relative;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  text-align: left;

                  .icon-left {
                    position: absolute;
                    color: #7c868e;
                    height: 14px;
                    top: 0;
                    bottom: 0;
                    left: 15px;
                    margin: auto;
                  }

                  .icon-right {
                    position: absolute;
                    color: $primary-color;
                    height: 14px;
                    top: 0;
                    bottom: 0;
                    right: 5px;
                    margin: auto;


                    &:hover {
                      color: #c13736;
                    }
                  }
                }
              }
              .button-send {
                vertical-align: middle;
                display: table-cell;
                font-size: 14px;
                border-radius: 2px;
                box-shadow: none;
                border: none;
                padding: 8px 25px;
              }
            }

            .sub {
              font-size: 9px;
              color: #5b6670;
              font-weight: 200;
              margin-top: 15px;
            }
          }

          .upload-footer {
            padding-top: 35px;
            text-align: center;

            a {
              .download-button {
                margin: auto;
                background: #3d97b5;
                font-weight: normal;
                font-size: 12px;
                max-width: 285px;
                padding: 10px;
                line-height: initial;
                border-radius: 2px;
                color: #FFF;

                i {
                  font-size: 14px;
                  padding-right: 15px;
                }
              }

              &:hover .download-button {
                background: #337c95;
              }
            }
          }
        }

        .tab-table {
          width: 100% !important;

          th {
            font-size: 10px;
            color: #7c868e;
            text-transform: uppercase;
            padding: 17px 25px;
            padding-right: 0;
            font-weight: normal;
            border-bottom: 1px solid #d0d3d5;

            outline: none !important;

            &:last-child {
              padding-right: 25px;
            }

            &.center {
              text-align: center;
            }
          }

          td {
            font-weight: 200;
            font-size: 12px;
            color: #5b6670;
            padding: 12px 25px;
            padding-right: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media screen and (max-width: 480px) {
              font-size: 10px;
              padding: 8px 15px;
            }

            &:first-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }

            &:last-child {
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              padding-right: 25px;
            }

            &.primary {
              font-weight: normal;
            }

            &.status {
              font-weight: normal;
              font-size: 10px;
              color: #a3a9ae;
              text-transform: uppercase;
            }

            &.message {
              max-width: 230px;
            }

            &.no-results,&.dataTables_empty {
              text-align: center;
              color: #a3a9ae;
            }

            &.center {
              text-align: center;
            }

            &.input {
              padding-top: 0;
              padding-bottom: 0;

              .form-group {
                position: relative;
                margin: 0;

                input {
                  width: 100%;
                  font-size: 12px;
                  color: #5b6670;
                  font-weight: 200;
                  border: 1px solid #d0d3d5;
                  border-radius: 2px;
                  box-shadow: none;
                  padding: 3px 10px;
                  padding-right: 40px;
                  min-width: 120px;

                  @media screen and (max-width: 768px) {
                    font-size: 10px;
                  }
                }

                button {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  width: 30px;
                  height: 30px;
                  background: #5b6670;
                  color: #FFF;
                  font-size: 14px;
                  border-top-right-radius: 2px;
                  border-bottom-right-radius: 2px;
                  border: none;
                  box-shadow: none;

                  &:hover {
                    background: #414950;
                  }

                  &:active {
                    background: #31383e;
                  }

                  i {
                    line-height: 29px;
                  }
                }
              }
            }

            &.edit {
              width: 50px;
              text-align: right;
              font-size: 17px;
              color: #3d97b5;

              &:hover {
                color: #337c95;
              }
            }


            &.delete {
              width: 50px;
              text-align: right;
              font-size: 17px;
              color: $primary-color;

              button {
                border: none;
                background: none;
                color: $primary-color;
                box-shadow: none;
                font-size: 17px;
                text-align: right;
                padding: 0;

                &:hover {
                  color: #c13736;
                }
              }

              &:hover {
                color: #c13736;
              }
            }
          }

          tbody {
            tr {
              &:nth-child(odd) {
                td {
                  background: #f8fbfd;
                }
              }

              &:nth-child(even) {
                td {
                  background: #eff3f6;
                }
              }
            }
          }
        }

        .form-tab {
          padding: 35px;

          @media screen and (max-width: 768px) {
            padding: 15px;
          }

          .form-group {

            .control-label {
              font-weight: normal;
              font-size: 10px;
              color: #5b6670;

              i {
                color: #3d97b5;
                font-size: 14px;
                padding-left: 5px;
              }

              a {
                &:hover i {
                  color: #337b93;
                }
              }

              .sub {
                display: block;
                font-size: 9px;
                font-weight: 200;
                margin-bottom: 5px;
                line-height: 1.2;
              }
            }

            .form-control {
              font-weight: 200;
              font-size: 12px;
              color: #1e1e1e;
              // border: 1px solid #c1c6c9;
              // border-radius: 2px;
              // box-shadow: none;
              padding: 5px 15px;
              height: auto;
              min-height: 34px;
              resize: none;

              &::-webkit-input-placeholder {
                  color: #c1c6c9;
              }
              &:-moz-placeholder {
              }
              &::-moz-placeholder {
                  color: #c1c6c9;
              }
              &:-ms-input-placeholder {
                  color: #c1c6c9;
              }
            }
          }

          .buttons-container {
            margin-top: 20px;

            .button-submit {
              padding: 5px 25px;
              text-transform: initial;
            }

            .reset-button {
              background: none;
              border: none;
              box-shadow: none;
              font-size: 12px;
              color: #c1c6c9;
              padding: 5px 25px;

              &:hover {
                color: #1e1e1e;
              }
            }
          }
        }
      }

      .tab-footer {
        font-weight: 200;
        font-size: 12px;
        color: #5b6670;
        padding: 10px 25px;

        @media screen and (max-width: 480px) {
          padding-left: 15px;
          padding-right: 15px;
          font-size: 10px;
        }

        .footer-label {
          line-height: 38px;
        }

        .pagination-table {
          float: right;
          display: table;
          @media screen and (max-width: 480px) {
            float: none;
          }

          a {
            display: table-cell;
            .pagination-button {
              font-size: 12px;
              font-weight: 200;
              padding: 7px 15px;
              border-left: 1px solid #d6d9db;
              border-top: 1px solid #d6d9db;
              border-bottom: 1px solid #d6d9db;
              @media screen and (max-width: 480px) {
                font-size: 10px;
                padding: 5px 10px;
              }
            }

            &:first-child .pagination-button{
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }

            &:last-child .pagination-button{
              border-right: 1px solid #d6d9db;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
            }

            &.active .pagination-button {
              color: $primary-color;
            }

            &:hover .pagination-button {
              color: $primary-color;
              background-color: #ebedef;
            }
          }
        }
      }
    }
  }
}

.popover-button {
  cursor: pointer;
}

.popover-title {
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 0;
}

.popover-content {
  font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 1.4;
}

#message-container {
  background-color: transparent;
  height: auto;
  z-index: 10 !important;
  // margin-top: -1px;
  width: 100%;
  padding-top: 0;

  .alert {
    margin: 0;
    font-family: 'Montserrat',  "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 0;
    font-size: 10px;
    font-weight: 200;
    display: block !important;
    padding: 0 !important;
    position: relative;
    height: auto !important;
    
    .alert-message {
      padding: 5px 0!important;
      padding-top: 6px !important;
      margin: auto;
      max-width: 1000px;
      display: flex;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        padding-left: 15px !important;
        padding-right: 35px !important;
      }

      a {
        border-radius: 2px;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: normal;
        padding: 10px 20px;
        text-align: center;
        background-color: #FFF !important;
        border: none;
        color: #1e1e1e;
        letter-spacing: 0;
        min-width: 195px;
        padding: 10px 15px!important;
        position: relative!important;
        display: inline-block;
        top: 0;
        float: right;
        margin: 0!important;

        &:hover {
          background-color: rgba(255,255,255,0.8) !important;
        }

        &:active {
          background-color: rgba(255,255,255,0.6) !important;
        }
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 1px;

      @media screen and (max-width: 992px) {
        right: 10px;
      }
    }

    &.alert-danger, &.alert-error {
      background-color: $primary-color;
      color: #FFF;
    }
    
    &.alert-warning {
      background-color: #f9be19;
      color: #1e1e1e;

      .alert-message {

        a {
          background-color: #ffd633!important;
          color: #1e1e1e;

          &:hover {
            background-color: #fbdf72 !important;
          }

          &:active {
            background-color: #f9cc1b !important;
          }
        }
      }
    }
  }
}